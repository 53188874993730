import React, { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import StudentPaymentHistoryFilter from "../../../Components/Filters/StudentPaymentHistoryFilter";
import StudentPaymentHistoryTable from "../../../Tables/AllStudents/StudentPaymentHistoryTable";
import { useAppSelector } from "../../../Redux/app/hooks";
import { useParams } from "react-router-dom";
import { getStudentPaymentHistory } from "../../../Apis/Private/students";
import { NavigationLink } from "../../../Tables/styles";
import { PiCaretLeft } from "react-icons/pi";
import { selectStudents } from "../../../Redux/features/studentsSlice";

const StudentPaymentHistory = () => {
  const { SessionId } = useAppSelector(selectStudents);
  const { personId } = useParams();

  useEffect(() => {
      personId && getStudentPaymentHistory(SessionId, personId);
  }, [SessionId, personId]);

  return (
    <>
      <NavigationLink to={`/Students`} className="flex-centered-items">
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <ItemsCenterJustifyBtw>
        <PageTitle>Payment History</PageTitle>
        <StudentPaymentHistoryFilter />
      </ItemsCenterJustifyBtw>
      <StudentPaymentHistoryTable />
    </>
  );
};

export default StudentPaymentHistory;
