import moment from "moment";
import PaymentMoreDropdown from "../../Components/Form/Dropdowns/PaymentMoreDropdown";
import { ImageCell } from "./PaymentTableCells";
import { levelOptions } from "../../Pages/PrivatePages/Hostels/util";
import TextCenteredInput from "../../Components/Form/Inputs/TextCenteredInput";
import { canPayInstallmentOptions } from "../../Pages/PrivatePages/Payments/util";
import SmallButton from "../../Components/Form/Buttons/SmallButton";
import { AiFillEdit } from "react-icons/ai";
import { store } from "../../Redux/app/store";
import RegularSelector from "../../Components/Form/Dropdowns/RegularSelector";
import { editPaymentFee } from "../../Apis/Private/payment";
import PaymentHistoryDropdown from "../../Components/Form/Dropdowns/PaymentHistoryDropdown";

// brands table data
export const PaymentsCols = [
  { label: "Name", field: "name" },
  { label: "Faculty", field: "faculty" },
  { label: "Department", field: "department" },
  { label: "InvoiceNo", field: "invoiceNo" },
  { label: "Gateway", field: "gateway" },
  { label: "date", field: "date" },
  { label: "fee", field: "fee" },
  { label: "Receipt", field: "receipt" },
  { label: "Amount", field: "amount" },
  { label: "Action", field: "action" },
];
export const PaymentHistoryCols = [
  { label: "InvoiceNo", field: "invoiceNo" },
  { label: "date", field: "date" },
  { label: "fee", field: "fee_type" },
  { label: "Action", field: "action2" },
];

export const paymentRows = (data: any) => {
  const res = data?.records?.map((payment: any) => {
    return {
      name: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={payment?.firstName}
          lastName={payment?.lastName}
          // userName="@hillary"
        />
      ),
      invoiceNo: <span>{payment?.invoiceNumber}</span>,
      gateway: <span>{payment?.paymentMeans}</span>,
      date: (
        <span>{moment(payment?.transactionDate).format("Do MMM YYYY")}</span>
      ),
      fee: <span>{payment?.feeScheduleName}</span>,
      fee_type: <span>{payment?.feeSchedule?.feeScheduleName}</span>,
      receipt: (
        <span style={{ width: `150px` }}>{payment?.tellerNoOrReceiptId}</span>
      ),
      faculty: <span>{payment?.facultyName}</span>,
      amount: <span>₦ {payment?.amount}</span>,
      department: <span>{payment?.departmentName}</span>,
      action: <PaymentMoreDropdown paymentId={payment?.paymentId} />,
      action2: <PaymentHistoryDropdown paymentId={payment?.paymentId} />,
    };
  });

  return res;
};

export const feeMappingCols = [
  { label: "Fee Schedule", field: "feeSchedule" },
  { label: "Pay By Installment", field: "payInstallment" },
  { label: "Department", field: "department" },
  { label: "Level", field: "level" },
  { label: "Session", field: "session" },
  { label: "Semester", field: "semester" },
  { label: "Gender", field: "gender" },
  { label: "Amount", field: "amount" },
  // { label: "Action", field: "action" },
];

export const feeScheduleCols = [
  { label: "Fee Id", field: "feeScheduleId" },
  { label: "Fee Schedule Name", field: "feeScheduleName" },
  { label: "Can Pay By Installment", field: "canPayInstallment" },
  { label: "Status", field: "status" },
  { label: "Action", field: "action2" },
];

export const feeMappingRows = (
  data: any,
  feeSchedule: any,
  department?: any,
  sessions?: any,
  semesters?: any,
  clickedButton?: any,
  setClickedButton?: any
) => {
  const res = data?.map((payment: any, index: number) => {
    let feeScheduleName = payment?.feeScheduleName;
    let canPayByInstallment: any;

    return {
      feeSchedule: (
        <span>
          {
            feeSchedule?.filter(
              (x: any) => x?.value === payment?.feeScheduleId
            )[0]?.label
          }
        </span>
      ),
      feeScheduleId: <span>{payment?.feeScheduleId}</span>,
      feeScheduleName: (
        <TextCenteredInput
          alignText="left"
          defaultValue={feeScheduleName}
          type="text"
          onChangeHandler={(e: any) => {
            feeScheduleName = e.target.value;
          }}
        />
      ),
      canPayInstallment: (
        <RegularSelector
          defaultValue={payment?.canPayInstallment}
          handleDropdownSelect={(e: any) => {
            canPayByInstallment = e.target.value;
          }}
          dropdownItems={canPayInstallmentOptions}
        />
      ),
      status: (
        <strong style={{ fontSize: "12px" }}>
          {payment?.isActive ? "Active" : "InActive"}
        </strong>
      ),
      payInstallment: (
        <span>
          {payment?.paymentInstallmentId === 1
            ? "Full Payment"
            : "Part Payment"}
        </span>
      ),
      level: (
        <span>
          {
            levelOptions?.filter((x: any) => x?.value === payment?.levelId)[0]
              ?.label
          }
        </span>
      ),
      session: (
        <span>
          {
            sessions?.filter((x: any) => x?.value === payment?.sessionId)[0]
              ?.label
          }
        </span>
      ),
      receipt: (
        <span style={{ width: `150px` }}>{payment?.tellerNoOrReceiptId}</span>
      ),
      semester: (
        <span>
          {
            semesters?.filter((x: any) => x?.value === payment?.semesterId)[0]
              ?.label
          }
        </span>
      ),
      gender: (
        <span>
          {payment.genderId
            ? payment?.genderId === 1
              ? "Male"
              : "Female"
            : null}
        </span>
      ),
      amount: <span>{`₦ ${payment?.feeAmount}`}</span>,
      department: (
        <span>
          {department?.filter((x: any) => x?.value === payment?.departmentId)[0]
            ?.label || "N/A"}
        </span>
      ),
      action: <PaymentMoreDropdown paymentId={payment?.feeDetailId} />,
      action2: (
        <SmallButton
          buttonText="Save"
          leftIcon={<AiFillEdit />}
          handleClick={() => {
            setClickedButton(index);
            console.log(canPayByInstallment);
            editPaymentFee(
              payment?.feeScheduleId,
              feeScheduleName,
              canPayByInstallment
                ? canPayByInstallment === "true"
                  ? true
                  : false
                : payment?.canPayInstallment
            );
          }}
          loading={
            clickedButton === index &&
            store.getState().payment.updatingFeePayment
          }
        />
      ),
    };
  });

  return res;
};
