import React from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import { useNavigate } from "react-router-dom";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../../../Redux/features/Application/ApplicationFormSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import AuthPageLayout from "../../../Components/ApplicationComponents/Layouts/AuthPageLayout";
import TextInputWithIcon from "../../../Components/ApplicationComponents/Form/Inputs/TextInputWithIcon";
import BigButton from "../../../Components/ApplicationComponents/Form/Buttons/BigButton";

const AddressInfo = () => {
  const dispatch = useAppDispatch();
  const { addressLine1, addressLine2 } = useAppSelector(selectapplicationForm);
  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
    navigate("/edit/o-level-result-details");
  };

  return (
    <AuthPageLayout authText="address information">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Country"
            name="country"
            handleChange={handleOnChange}
            value={"Nigeria"}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Address Line 1"
            name="addressLine1"
            handleChange={handleOnChange}
            value={addressLine1}
            characterLimit={48}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Address Line 2"
            name="addressLine2"
            handleChange={handleOnChange}
            value={addressLine2}
            characterLimit={48}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default AddressInfo;
