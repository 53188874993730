import { useAppSelector } from "../../Redux/app/hooks";
import { selectPayment } from "../../Redux/features/paymentSlice";
import { PaymentHistoryCols, paymentRows } from "../AllPayments/utils";
import CustomTable2 from "../CustomTable2";

const StudentPaymentHistoryTable = () => {
  const { studentPaymentHistory, gettingStudentPaymentHistory } =
    useAppSelector(selectPayment);
  const rows = paymentRows({ records: studentPaymentHistory });

  return (
    <CustomTable2
      data={{ rows, columns: PaymentHistoryCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingStudentPaymentHistory}
      noRecord={studentPaymentHistory?.length === 0}
    />
  );
};

export default StudentPaymentHistoryTable;
