import React from "react";
import { selectUtil } from "../../Redux/features/utilsSlice";
import { filter } from "../../Pages/PrivatePages/util";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import ReactSuiteDropdown from "../Form/Dropdowns/NewDropdown";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../Redux/features/studentsSlice";

const StudentPaymentHistoryFilter = () => {
  const { sessions } = useAppSelector(selectUtil);
  const { SessionId } = useAppSelector(selectStudents);
  const dispatch = useAppDispatch();
  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  return (
    <ReactSuiteDropdown
      dropdownItems={sessionFilter}
      title={`Session`}
      handleDropdownSelect={handleSelect}
      name="SessionId"
      value={SessionId}
    />
  );
};

export default StudentPaymentHistoryFilter;
